<template>
  <div class="box container">
    <div class="header">
      <div class="title-box">
        <p class="title">技巧文章</p>
      </div>
    </div>
    <div class="cont_box">
      <div class="article_cont">
        <p class="title" v-if="data">{{ data.title }}</p>
        <p class="time" v-if="data">{{ get_time(data.created_at) }}</p>
        <div class="content" v-if="data" v-html="data.content"></div>
      </div>
      <div class="content" style="color: #fff;">
          <div class="articles_row" v-for="tip in list" :key="tip.id" @click="articleAction(tip.id)">
            <div :style="{color: id == tip.id ? '#fa3e3e' : '#282828'}">{{ tip.title }}</div>
            <span>{{ date_change(tip.updated_at) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'articlelist',
  data () {
    return {
      data: '',
      list: [],
      id: ''
    }
  },
  mounted () {
    const query = this.$route.query
    this.id = /\d+/.test(query?.id) ? query?.id : ''
    this.get_list()
  },
  methods: {
    add_0 (v) {
      if (parseInt(v) < 10) {
        return '0' + v
      }
      return v
    },
    get_time (t) {
      var time = new Date(parseInt(t) * 1000)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      var h = time.getHours()
      var mm = time.getMinutes()
      return y + '-' + this.add_0(m) + '-' + this.add_0(d) + ' ' + this.add_0(h) + ':' + this.add_0(mm)
    },
    date_change (t) {
      const date = new Date(parseInt(t) * 1000)
      return this.add_0(date.getMonth() + 1) + '-' + this.add_0(date.getDate())
    },
    get_list () {
        this.$api.home().then(res => {
            this.list = res.data?.tips
            if (/\d+/g.test(this.id)) {
              this.requestGetArticle(this.id)
            } else {
              this.id = this.list.length > 0 ? this.list[0]['id'] : ''
              if (/\d+/g.test(this.id)) {
                this.requestGetArticle(this.id)
              }
            }
            this.$loading.hide()
        }).catch(err => {
            this.$loading.hide()
            this.$message.error(err)
        })
    },
    articleAction (id, obj) {
      this.id = id
      this.requestGetArticle(id)
    },
    requestGetArticle (id) {
      this.$loading.show()
      this.$api.articleDetail(id).then(res => {
        this.data = res.data
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.box {
  padding-top: 30px;
  padding-bottom: 40px;

  > .header {
    padding: 0 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 45px;

      > .title {
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }
    }
  }

  > .title {
    padding-top: 40px;
    padding-bottom: 30px;
    font-size: 32px;
    font-weight: 400;
    color: #222;
    text-align: center;
    background-color: #fff;
  }

  > .time {
    font-size: 14px;
    color: #959595;
    text-align: center;
    background-color: #fff;
  }
  .cont_box {
    width: 100%;
    display: flex;
  }
  .article_cont {
    flex: 1;
    margin-right: 10px;
    background-color: #fff;
    > .title {
      padding-top: 40px;
      padding-bottom: 30px;
      font-size: 32px;
      font-weight: 400;
      color: #222;
      text-align: center;
      background-color: #fff;
    }

    > .time {
      font-size: 14px;
      color: #959595;
      text-align: center;
      background-color: #fff;
    }
    > .content {
      margin: 0;
      padding: 36px 30px;
      padding-top: 50px;
      font-size: 16px;
      line-height: 32px;
      word-break: break-all;
      word-wrap: break-word;
      text-align: justify;
      text-justify: inter-word;
      background-color: #fff;
    }
  }
  .content {
    margin: 0;
    padding: 0 17px;
    background-color: #fff;
  .articles_row {
      width: 360px;
      font-size: 16px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      >div {
        width: 250px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      >span {
        font-weight: normal;
        color: #959595;
      }
    }
    .articles_row:hover {
      color: #fa3e3e;
    }
  }
}
</style>
